


































































































































































import tipos_fichajeModule from "@/store/modules/tipos_fichaje-module";
import usuarioModule from "@/store/modules/usuario-module";
import { Component, Vue, Watch } from "vue-property-decorator";
import DxDateBox from "devextreme-vue/date-box";
import fichajes_empleadoModule from "@/store/modules/fichajes_empleado-module";
import { fichajes_form_masive } from "@/shared/dtos/fichajes_form_masive";
import { MessageService } from "@/shared/services/message-service";
import { RouterNames } from "@/router/routernames";
import { API } from "@/shared/api";
import centro_trabajoModule from "@/store/modules/centro_trabajo-module";

@Component({
  $_veeValidate: { validator: "new" },
  components: {
    DxDateBox,
    FullDynamicTable: () => import("../dynamic_tables/full_dynamic_table.vue"),
    Xbtn: () => import("@/components/buttons/x-btn.vue"),
  },
})
export default class FichajesTabla extends Vue {
  public shw_form: boolean = false;

  public form_masive: fichajes_form_masive = new fichajes_form_masive();

  created() {
    usuarioModule.getusuarios();
    tipos_fichajeModule.gettipos_fichajes();
    centro_trabajoModule.getcentro_trabajos();
  }

  get usuarios() {
    return usuarioModule.usuarios;
  }

  get tipos_fichajes() {
    return tipos_fichajeModule.tipos_fichajes;
  }
  get centro_trabajo() {
    return centro_trabajoModule.centro_trabajos;
  }
  OpenFormmanual() {
    this.shw_form = true;
  }
  OpenExcel() {
    this.$router.push({ name: RouterNames.dxtable, params: { id: "26" } });
  }
  aceptar_fichaje() {
    this.$validator.validateAll().then((allOk) => {
      if (allOk) {
        fichajes_empleadoModule
          .guardarfichajes_masivos(this.form_masive)
          .then((x) => {
            //hecho
            this.shw_form = false;
            MessageService.alertRespose(
              "Fichaje",
              "Ha fichado correctamente",
              () => {}
            );
          });
      }
    });
  }

  cancelar_fichaje() {
    this.shw_form = false;
  }

  @Watch("shw_form")
  cargar_datos_necesarios() {
    if (this.shw_form) {
      if (usuarioModule.usuarios.length === 0) {
        usuarioModule.getusuarios();
      }
      if (tipos_fichajeModule.tipos_fichajes.length === 0) {
        tipos_fichajeModule.gettipos_fichajes();
      }
    }
  }
}
