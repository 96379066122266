import { BaseDto } from '@/shared/dtos/base-dto';

export class fichajes_form_masive extends BaseDto {
    public nombre !: string;
    public codigo !: string;
    public inicio!: Date;
    public fin!: Date;
    public id_usuario!: number;
    public hora_inicio!: string;
    public hora_fin!: string;
    public id_tipo_fichaje!: number;
    public id_centro_trabajo!: number;
    public chk_lunes!:boolean;
    public chk_martes!:boolean;
    public chk_miercoles!:boolean;
    public chk_jueves!:boolean;
    public chk_viernes!:boolean;
    public chk_sabado!:boolean;
    public chk_domingo!:boolean;
 } 
